import { useState, useEffect } from 'react';

interface CountryCheckResult {
  isLoading: boolean;
  isAllowed: boolean;
  error: string | null;
  country: string | null;
}

export const useCountryCheck = (blockedCountries: string[] = []): CountryCheckResult => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAllowed, setIsAllowed] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [country, setCountry] = useState<string | null>(null);

  useEffect(() => {
    const checkCountryAccess = async () => {
      try {
        const response = await fetch(
          'https://extreme-ip-lookup.com/json?key=Qn97RtiI2gwjStzJJjuG',
          {
            headers: {
              Accept: 'application/json',
              'Cache-Control': 'no-cache'
            }
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseText = await response.text();
        let jsonData;

        // Handle JSONP format
        if (responseText.startsWith('showIP(')) {
          const jsonStr = responseText.replace('showIP(', '').replace(/\);?$/, '');
          jsonData = JSON.parse(jsonStr);
        } else {
          // Try direct JSON parse
          jsonData = JSON.parse(responseText);
        }

        if (!jsonData.countryCode) {
          throw new Error('No country code in response');
        }

        setCountry(jsonData.country);
        setIsAllowed(!blockedCountries.includes(jsonData.countryCode));
      } catch (err) {
        console.error('Error checking country access:', err);
        setError('Unable to verify your location. Please try again later.');
        setIsAllowed(true); // Default to allowed on error
      } finally {
        setIsLoading(false);
      }
    };

    checkCountryAccess();
  }, [blockedCountries]);

  return { isLoading, isAllowed, error, country };
};
