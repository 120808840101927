import React from 'react';

interface BlockedCountryMessageProps {
  country?: string | null;
}

export const BlockedCountryMessage: React.FC<BlockedCountryMessageProps> = ({ country }) => (
  <div className="ss-flex ss-items-center ss-justify-center ss-min-h-screen ss-bg-gray-50">
    <div className="ss-w-96 ss-p-8 ss-bg-white ss-rounded-lg ss-shadow-lg">
      <h1 className="ss-text-2xl ss-font-bold ss-text-red-600 ss-mb-4">Access Restricted</h1>
      <p className="ss-text-gray-600 ss-mb-4">
        We apologize, but this website is not available in {country}.
      </p>
      <div className="ss-text-sm ss-text-gray-500">
        If you believe this is an error, please send an email to support:{' '}
        <a href="mailto:hi@sendsprint.com">here</a>
      </div>
    </div>
  </div>
);
