import StyleProvider from '@sendsprint/ui-react/dist/providers/StyleProvider/StyleProvider';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import {
  EnvProvider,
  AuthProvider,
  MixpanelProvider,
  ToastProvider,
  DashboardProvider,
  ConnectProvider,
  NethoneProvider
} from '@src/contexts';
import { ErrorBoundary } from '@sentry/react';
import QueryProvider from '@src/providers/query-provider';
import Error from '@src/pages/error';
import AppLayoutInner from './components/appLayoutInner';
import { Outlet } from 'react-router-dom';

const AppLayout = () => {
  return (
    <HelmetProvider>
      <StyleProvider>
        <EnvProvider>
          <ErrorBoundary fallback={({ resetError }) => <Error resetError={resetError} />}>
            <MixpanelProvider>
              <ToastProvider autoDismiss={true} placement="bottom-right">
                <QueryProvider>
                  <AuthProvider>
                    <ConnectProvider>
                      <DashboardProvider>
                        <NethoneProvider>
                          <AppLayoutInner>
                            <Outlet />
                          </AppLayoutInner>
                        </NethoneProvider>
                      </DashboardProvider>
                    </ConnectProvider>
                  </AuthProvider>
                </QueryProvider>
              </ToastProvider>
            </MixpanelProvider>
          </ErrorBoundary>
        </EnvProvider>
      </StyleProvider>
    </HelmetProvider>
  );
};

export default AppLayout;
