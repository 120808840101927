import { createBrowserRouter } from 'react-router-dom';
import Home from '@src/pages/home';
import NotFound from '@src/pages/notFound';
import AppLayout from '@src/layouts/appLayout';
import AuthLayout from '@src/layouts/auth';
import DashboardLayout from '@src/layouts/dashboard';
import GeneralLayout from '@src/layouts/general';
import { authRoutes } from './subRoutes/authRoutes';
import { dashboardRoutes } from './subRoutes/dashboardRoutes';
import { generalRoutes } from './subRoutes/generalRoutes';
import ErrorBoundary from '@src/components/errorBoundary';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <Home />
      },
      {
        element: <AuthLayout />,
        children: authRoutes
      },
      {
        element: <DashboardLayout />,
        children: dashboardRoutes
      },
      {
        element: <GeneralLayout />,
        children: generalRoutes
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
]);
