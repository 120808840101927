import Box from '@sendsprint/ui-react/dist/components/Box';
import React, { ReactNode } from 'react';
import cs from 'classnames';
import Aside from './components/aside';
import { Navigate, Outlet } from 'react-router-dom';
import { useAccount } from '@src/contexts';
import { Path } from '@src/navigations/routes';

interface Props {
  showAside?: boolean;
  asideContent?: ReactNode;
}

const AuthLayout = ({ showAside = true, asideContent }: Props) => {
  const { isLoggedIn } = useAccount();

  if (isLoggedIn) {
    return <Navigate to={Path.Dashboard} replace />;
  }

  return (
    <Box className="">
      <Outlet />
      <Box
        className={cs(
          ' ss-fixed ss-bg-primary1-500 ss-w-[400px] xl:ss-w-140 ss-h-screen ss-top-0 ss-right-0',
          {
            'ss-hidden lg:ss-block': showAside,
            'ss-hidden': !showAside
          }
        )}>
        {showAside && <Aside asideContent={asideContent} />}
      </Box>
    </Box>
  );
};

export default AuthLayout;
